import InterceptorService from "./InterceptorService";
import axios from "axios";
import { API_URL, CURRENCY_SEPARATOR } from "../config/constant";
import secureStorage from "../config/encrypt";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import jsPDF from 'jspdf';
const BASE_PATH = API_URL.BASE_PATH_PREFIX;
const finaljson= {
  ...InterceptorService,
  getThousandsGroupRegex(thousandsGroupStyle) {
    switch (thousandsGroupStyle) {
      case "lakh":
        return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;

      case "wan":
        return /(\d)(?=(\d{4})+(?!\d))/g;

      case "thousand":
      default:
        return /(\d)(?=(\d{3})+(?!\d))/g;
    }
  },
  numberFormatWithDots(str,decimalPlace = 2) {
    str = str?.toString().split('.');
    let thousandsGroupRegex = this.getThousandsGroupRegex("thousand");
    let index = str?.[0].search(/[1-9]/);
    index = index === -1 ? str?.[0].length : index;
    return (
      str?.[0].substring(0, index) +
      str?.[0].substring(index, str?.[0].length).replace(thousandsGroupRegex, "$1.") + 
      (str?.[1] !== undefined ? ',' + str?.[1].substring(0, decimalPlace) : '')
    );
  },
  uploadFilesToS3(postdata, headers) {
    headers = headers || {};
    return axios.post(
      `${API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
      postdata,
      { headers }
    );
  },
  __numberWithCommaSeperator(num) {
    num = num == null ? "" : "" + num;
    let p = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + CURRENCY_SEPARATOR);
    return p;
  },
  __deepCopy(object){   
    return JSON.parse(JSON.stringify(object));
  },
  saveDoc(body) {
    return this.post(`/${BASE_PATH}/lead/save_doc`, body);
  },

  updateLoanDocument(body) {
    return this.post(`/${BASE_PATH}/lead/update_doc`, body);
  },

  deleteLoanDocument(body) {
    return this.post(`/${BASE_PATH}/lead/remove_image`, body);
  },

  getDocHistory(body) {
    return this.post(`/${BASE_PATH}/lead/get_doc_history`, body);
  },

  manageDocumentAction(body) {
    return this.post(`/${BASE_PATH}/lead/manage_doc_actions`, body);
  },

  getDashboardCard(body) {
    return this.post(`/${BASE_PATH}/lead/lead_dashboard`, body);
  },

  getConfig(body) {
    return this.post(`/${BASE_PATH}/config/get`, body);
  },

  getLoanSummary(body) {
    return this.post(`/${BASE_PATH}/lead/get_loan_summary`, body);
  },

  getCustomerLoanDetails(body) {
    return this.post(`/${BASE_PATH}/customer/get_leaddetails`, body);
  },
  getLoanExpected(body) {
    return this.post(`/${BASE_PATH}/lead/get_loan_expected`, body);
  },
  getDocumentlist(body) {
    return this.post(`/${BASE_PATH}/lead/get_documentlist`, body);
  },
  updatePersonalInfo(body) {
    return this.post(`/${BASE_PATH}/customer/update_personalinfo`, body);
  },
  getLoanCases(body) {
    return this.post(`/${BASE_PATH}/lead/getleads`, body);
  },
  getotp(body) {
    return this.post(`/${BASE_PATH}/user/login_with_email`, body);
  },
  removeUser(body) {
    return this.post(`/${BASE_PATH}/users/remove_user`, body);
  },
  validateOtp(body) {
    return this.post(`/${BASE_PATH}/user/login-otp`, body);
  },
  userLogOut(body) {
		return this.post(`/${BASE_PATH}/user/logout`, body);
	},
  getFinancierList(userid, body) {
    return this.get(
      `/${BASE_PATH}/financier/get_company?user_id=${userid}&source=LOANBOX`, body
    );
  },
  addFinancier(body) {
    return this.post(`/${BASE_PATH}/financier/add_company`, body);
  },
  updateStatusFinancier(body) {
    return this.post(`/${BASE_PATH}/financier/update_status`, body);
  },
  updateMarginBeforeBooster(body) {
    return this.post(`/${BASE_PATH}/financier/update_margin_before_booster`, body);
  },
  addFinancierBranch(body) {
    return this.post(`/${BASE_PATH}/financier/add_branch`, body);
  },
  updateStatusFinancierBranch(body) {
    return this.post(`/${BASE_PATH}/financier/update_branch_status`, body);
  },
  getBranchesList(body) {
    return this.post(`/${BASE_PATH}/financier/get_branches_list`, body);
  },
  getAllBranchesList(body) {
    return this.post(`/${BASE_PATH}/financier/get_all_branches_list`, body);
  },
  bulkUploadFinancierBranch(body) {
    return this.post(`/${BASE_PATH}/financier/bulk_upload_branch`, body);
  },
  getLeadDetails(body) {
    return this.post(`/${BASE_PATH}/lead/lead_details`, body);
  },
  importMrpCSV(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_csv`, body, headers);
  },
  getPercentage(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/get_percentage`, body);
  },
  importVariantCSV(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_variant`, body, headers);
  },
  importInsuranceCSV(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_insurance_rate`, body, headers);
  },
  get_mmv_all(body) {
    return this.post(`/${BASE_PATH}/rule_engine/mmv_all`, body);
  },
  updateOtoMapping(body) {
    return this.post(`/${BASE_PATH}/rule_engine/update_oto_mapping`, body);
  },
  get_calculate_loan(body) {
    return this.post(`/${BASE_PATH}/rule_engine/calculate_loan`, body);
  },
  get_loan_details_by_leadid(body) {
    return this.post(`/${BASE_PATH}/rule_engine/get_calculated_loan`, body);
  },
  save_calculated_data(body) {
    return this.post(`/${BASE_PATH}/rule_engine/save_calculated_data`, body);
  },
  getRuleEngineConfig(body) {
    return this.post(`/${BASE_PATH}/rule_engine/core_config`, body);
  },
  udateRuleEngineConfig(body) {
    return this.post(`/${BASE_PATH}/rule_engine/update_config`, body);
  },
  genrateAndDownloadCalculatorPdf(body) {
    return this.post(`/${BASE_PATH}/lead_pdf/generate_cal_pdf`, body);
  },
  updateApplication(body) {
    return this.post(`/${BASE_PATH}/lead/update_status`, body);
  },
  check_req_doc(body) {
    return this.post(`/${BASE_PATH}/lead/check_req_doc`, body);
  },
  updateLeadDealer(body) {
    return this.post(`/${BASE_PATH}/lead/update_dealer`, body);
  },
  updateLeadBranch(body) {
    return this.post(`/${BASE_PATH}/lead/update_branch`, body);
  },
  getRangeList(body) {
    return this.get(`/${BASE_PATH}/booster/list`, body);
  },
  updateStatus(body) {
    return this.post(`/${BASE_PATH}/booster/update_status`, body);
  },
  saveRange(body) {
    return this.post(`/${BASE_PATH}/booster/save_range`, body);
  },
  getRangeAmountList(body) {
    return this.get(`/${BASE_PATH}/booster/range_amount_list`, body);
  },
  updateAmountStatus(body) {
    return this.post(`/${BASE_PATH}/booster/update_range_amount_status`, body);
  },
  saveRangeAmount(body) {
    return this.post(`/${BASE_PATH}/booster/save_range_amount`, body);
  },
  importMMVCSV(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_mmv_bcamf`, body, headers);
  },
  importMMVBrandType(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_mmv_brand_type`, body, headers);
  },
  importUpdateLead(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/update_lead_data`, body, headers);
  },
  importBuanaMmvCsv(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_mmv_buana`, body, headers);
  },
  importMpmMmvCsv(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_mmv_mpm`, body, headers);
  },
  getModelCode(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/get_model_code`, body, headers);
  },
  importSmsTotalLoanAmt(body, headers) {
    return this.post(`/${BASE_PATH}/rule_engine/import_sms_tla`, body, headers);
  },
  getRoleList(body, headers) {
    return this.post(`/${BASE_PATH}/role/list_role`, body, headers);
  },
  getUserAccess(body, headers) {
    return this.post(`/${BASE_PATH}/role/get_user_access_features`, body, headers);
  },
  updateRoleStatus(body, headers) {
    return this.post(`/${BASE_PATH}/role/update_status`, body, headers);
  },
  saveRole(body, headers) {
    return this.post(`/${BASE_PATH}/role/save_role`, body, headers);
  },
  getUserList(body, headers) {
    return this.post(`/${BASE_PATH}/users/list_user`, body, headers);
  },
  getUserListWithRoles() {
    return this.post(`/${BASE_PATH}/users/list_user_with_roles`);
  },
  updateUserStatus(body, headers) {
    return this.post(`/${BASE_PATH}/users/update_status`, body, headers);
  },
  saveUser(body, headers) {
    return this.post(`/${BASE_PATH}/users/create_user`, body, headers);
  },
  getFeatureList(body, headers) {
    return this.post(`/${BASE_PATH}/role/list_feature`, body, headers);
  },
  logWhatsappRedirect(body){
    return this.post(`/${BASE_PATH}/lead/log_whatsapp_redirect`, body);
  },
  addToDialer(body, headers) {
    return this.post(`/${BASE_PATH}/lead/add_to_dialer`, body, headers);
  },
  getDialerHistory(body, headers) {
    return this.post(`/${BASE_PATH}/lead/dialer_history`, body, headers);
  },
  getBroBmDetails(body, headers) {
    return this.post(`/${BASE_PATH}/lead/get_bro_bm_info`, body, headers);
  },
  MMVList(body) {
    return this.get(`/${BASE_PATH}/rule_engine/sync_mmv`, body);
  },
  MMVDownloadMapping(body) {
    return this.get(`/${BASE_PATH}/rule_engine/download_mmv_mapping`, body);
  },
  getDeviationConfig(body) {
    return this.get(`/${BASE_PATH}/lead/get_deviation_config`, body);
  },
  udateDeviationConfig(body, headers) {
    return this.post(`/${BASE_PATH}/lead/update_deviation_config`, body, headers);
  },
  cancelDoScheme(body) {
    return this.post(`/${BASE_PATH}/lead/cancel_do_scheme`, body);
  },
  getFinancierDropdownList() {
    return this.get(
      `/${BASE_PATH}/financier/get_company/list?source=GCLOUD`
    );
  },
  generateDocByDocIds(body) {
    return this.post(`/${BASE_PATH}/lead_pdf/generate_doc_pdf`, body);
  },
  getTATreport(body) {
    return this.post(`/${BASE_PATH}/report/get_tat_report`, body);
  },
  getUserTATreport(body) {
    return this.post(`/${BASE_PATH}/report/get_user_tat_report`, body);
  },
  getMobileInfo(body){
    return this.post(`/${BASE_PATH}/lead/get_mobile_info`, body);
  },
  getToken() {
    let fetchHeader = secureStorage.getItem("loginUserInfo")
      ? secureStorage.getItem("loginUserInfo")
      : {};
    return fetchHeader && fetchHeader.data && fetchHeader.data.token
      ? fetchHeader.data.token
      : null;
  },
  importRefundReceived(body, headers) {
    return this.post(`/${BASE_PATH}/lead/import_refund_received`, body, headers);
  },
  getLeadRemarks(body) {
    return this.post(`/${BASE_PATH}/lead/get_lead_remarks`, body);
  },
  saveLeadRemarks(body) {
    return this.post(`/${BASE_PATH}/lead/save_lead_remark`, body);
  },
  getMMVMrp(body) {
    return this.post(`/${BASE_PATH}/rule_engine/get_mrp`, body);
  },
  saveImageDownloadLog(body) {
    return this.post(`/${BASE_PATH}/lead/save_image_download_log`, body);
  },
  getSchemeUserList(body) {
    return this.get(`/${BASE_PATH}/lead/get_margin_before_booster_group`, body);
  },
  getMarginBeforeBoosterAmount(body) {
    return this.post(`/${BASE_PATH}/lead/get_margin_before_booster_amount`, body);
  },
  saveAndUpdateMarginBeforeBooster(body) {
    return this.post(`/${BASE_PATH}/lead/save_margin_before_booster_scheme`, body);
  },
  getSentToFinancierTemplate(body) {
    return this.post(`/${BASE_PATH}/lead/get_sent_to_financier_template`, body);
  },
  saveSentToFinancier(body) {
    return this.post(`/${BASE_PATH}/lead/save_sent_to_financier`, body);
  },
  getSentToFinancierConfig(body) {
    return this.get(`/${BASE_PATH}/rule_engine/get_sent_to_financier_config`, body);
  },
  getTemplateFinancierList(body) {
    return this.get(`/${BASE_PATH}/rule_engine/sent_to_financier_config`, body);
  },
  getSentToFinancierTemplateDetails(body) {
    return this.post(`/${BASE_PATH}/rule_engine/get_sent_to_financier_group`, body);
  },
  saveSentToFinancierTemplate(body) {
    return this.post(`/${BASE_PATH}/rule_engine/save_sent_to_financier_template`, body);
  },
  saveLoanDataDetails(body) {
    return this.post(`/${BASE_PATH}/lead/save_loan_data`, body);
  },
  getLoanDataDetails(body) {
    return this.post(`/${BASE_PATH}/lead/get_loan_data`, body);
  },
  getLoanDataHistory(body) {
    return this.post(`/${BASE_PATH}/lead/get_loan_data_history`, body);
  },
  getFinancier(source) {
    return this.get(
      `/${BASE_PATH}/financier/get_company?source=${source}`
    );
  },
  getFunnelReport(body) {
    return this.post(`/${BASE_PATH}/report/get_funnel_report`, body);
  },
  saveFunnelReportDownloadLog(body) {
    return this.post(`/${BASE_PATH}/report/save_funnel_report_download_log`, body);
  },
  // Adira Integration
  getProvinceCity(body) {
    return this.post(`/${BASE_PATH}/residence/get_province_city`, body)
  },
  getDistrictSubdistrict(body) {
    return this.post(`/${BASE_PATH}/residence/get_district_subdistrict`, body)
  },
  loginCaptcha() {
    return this.get(`/${BASE_PATH}/user/get_captcha_image`);
  },
  redisFlag() {
    return this.get(`/${BASE_PATH}/user/get_redis_flag`);
  },
  savePaymentRequestConfigData(body) {
    return this.post(`/${BASE_PATH}/lead/save_payment_request_data`, body);
  },
  getPaymentRequestConfigData() {
    return this.post(`/${BASE_PATH}/lead/get_payment_request_data`);
  },
  getPaymentRequestCharge(body) {
    return this.post(`/${BASE_PATH}/lead/get_payment_request_charge`, body);
  },
  // Agent Fee scheme APIs
  getAgentFeeSchemeData(body) {
    return this.post(`/${BASE_PATH}/financier/get_agent_fee_scheme_data`, body);
  },
  saveAgentFeeScheme(body) {
    return this.post(`/${BASE_PATH}/financier/save_agent_fee_scheme`, body);
  },
  saveConfigBranchData(body) {
    return this.post(`/${BASE_PATH}/triivco/save_config_branch_data`, body);
  },

  getAgentFeeSchemeList(body){
    return this.post(`/${BASE_PATH}/financier/get_agent_fee_scheme_list`, body);
  },
  getAgentFeeBMSchemeHistoryData(body) {
    return this.post(`/${BASE_PATH}/financier/get_agent_fee_bm_scheme_data`, body);
  },
  deleteSelectedTabAgentFee(body){
    return this.post(`/${BASE_PATH}/financier/delete_agent_fee_scheme`, body)
  },
  updateUserLoginStatus(body) {
    return this.post(`/${BASE_PATH}/users/update_user_login_status`, body);
  },
  logMobileView(body) {
    return this.post(`/${BASE_PATH}/lead/log_mobile_view`, body);
  },
  getFinancierMrp(body) {
    return this.post(`/${BASE_PATH}/financier_rule_engine/get_financier_mrp`, body);
  },
  getFinancierCalculation(body) {
    return this.post(`/${BASE_PATH}/financier_rule_engine/financier_calculate_loan`, body);
  },
  getAuth() {
    return this.get("/mrp-url/inventory_funding/get_auth");
  },
  getInventoryFundingConfigData(body, headers=null) {
    return this.post(`/mrp-url/inventory_funding/get_form_fields_car_mrp`, body);
  },
  getInventoryFundingCarQuote(body, headers=null) {
    return this.post(`/mrp-url/inventory_funding/get_car_quote`, body)
  },
  getDealerList() {
    return this.get(`/${BASE_PATH}/rule_engine/get_dealer_list`);
  },  
  getAdvancePaymentConfigData(body) {
    return this.get(`/${BASE_PATH}/rule_engine/get_advance_payment_limit_scheme_config`, body);
  },
  getAdvancePaymentSchemeDetails(body) {
    return this.get(`/${BASE_PATH}/rule_engine/get_advance_payment_limit_scheme_details`, body);
  },
  saveAdvancePaymentConfigData(body) {
    return this.post(`/${BASE_PATH}/rule_engine/save_advance_payment_limit_scheme_config`, body);
  },
  deleteAdvancePaymentConfigData(body) {
    return this.post(`/${BASE_PATH}/rule_engine/delete_advance_payment_limit_scheme_config`, body);
  },
  getTaxSchemeUserList(body) {
    return this.get(`/${BASE_PATH}/lead/get_tax_booster_group`, body);
  },
  getTaxBoosterPercent(body) {
    return this.post(`/${BASE_PATH}/lead/get_tax_booster_percent`, body);
  },
  saveAndUpdateTaxBooster(body) {
    return this.post(`/${BASE_PATH}/lead/save_tax_booster_scheme`, body);
  },
  getFinancierWiseTaxBoosterPercent(body) {
    return this.post(`/${BASE_PATH}/sfa/get_tax_booster_percentage`, body);
  },
  getHoRefundConfigData(body) {
    return this.post(`/${BASE_PATH}/config/get_ho_refund_config_data`, body)
  },
  saveHoRefundConfigData(body) {
    return this.post(`/${BASE_PATH}/config/save_ho_refund_config_data`, body)
  },
  getHoRefundCalculationData(body) {
    return this.post(`/${BASE_PATH}/config/get_ho_refund_calculation_data`, body);
  },
  saveHoRefundCalculationData(body) {
    return this.post(`/${BASE_PATH}/config/save_ho_refund_calculation_data`, body);
  },
  getInterestRateData(body) {
    return this.post(`/${BASE_PATH}/config/get_interest_rate_data`, body);
  },
  saveInterestRateData(body) {
    return this.post(`/${BASE_PATH}/config/save_interest_rate_data`, body);
  },
  getTaxComponentPercent() {
    return this.get(`/${BASE_PATH}/lead/get_tax_component_percent`);
  },
  saveAndUpdateTaxComponent(body) {
    return this.post(`/${BASE_PATH}/lead/save_tax_component`, body);
  },
  getQuotesByFinancier(body) {
    return this.post(`/${BASE_PATH}/lead_new_flow/get_quote`, body)
  },
  saveQuotesMMV(body) {
    return this.post(`/${BASE_PATH}/lead_new_flow/update_quote_mmv`, body);
  },
  getApiDataConfig(body) {
    return this.post(`/${BASE_PATH}/lead/get_api_config_data`, body);
  },
  saveAndUpdateDealerIncentive(body) {
    return this.post(`/${BASE_PATH}/lead/save_dealer_monthly_incentive`, body);
  },
  saveAndUpdateDealerIncentiveSlab(body) {
    return this.post(`/${BASE_PATH}/lead/save_dealer_monthly_incentive_slab`, body);
  },
  saveLoanRepayment(body) {
    return this.post(`/dif/lead/save_loan_repayment_transactions`, body);
  },
  get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  },
  saveTatBreachConfigData(body) {
    return this.post(`/loan/config/save_tat_breach_config_data`, body)
  },
  saveFinancierApplicationInfo(body) {
    return this.post("/loan/lead/save_financier_application_info", body);
  },
  saveBranchConfigData(body) {
    return this.post(`/${BASE_PATH}/lead/save_branch_config`, body);
  },
  getCoreMMV() {
    return this.get(`/${BASE_PATH}/rule_engine/get_mmv_core`);
  }, 
  saveNimDataVerification(body) {
    return this.post(`/${BASE_PATH}/lead_new_flow/nim_data_verification`, body);
  }, 
  importBuanaDailyReport(body, headers) {
    return this.post(`/${BASE_PATH}/lead/import_buana_loan_data`, body, headers);
  },
  getDealerEntityGroupList(body) {
    return this.get(`/${BASE_PATH}/lead/get_dealer_entity_group`, body);
  },
  getDealerEntity(body) {
    return this.post(`/${BASE_PATH}/lead/get_dealer_entity`, body);
  },
  saveAndUpdateDealerEntityScheme(body) {
    return this.post(`/${BASE_PATH}/lead/save_dealer_entity_scheme`, body);
  },
  calculateServiceFee(body) {
    return this.post(`/${BASE_PATH}/lead/calculate_service_fee`, body);
  },
  getWHTConfig(body) {
    return this.post(`/${BASE_PATH}/config/get_wht_tax_config_data`, body);
  },
  saveWHTConfigData(body) {
    return this.post(`/loan/config/save_wht_tax_config_data`, body)
  },
  async downloadAll(docs,leadDetails,showBpkp=false) {
    let zip = new JSZip();
    let folder = zip.folder('collection');
    let i=1;
    for (const sl of docs){
        if( sl?.doc_path_watermark){ 
            let extenion=this.get_url_extension(sl?.doc_path_watermark);
            let imageUrl = sl?.doc_path_watermark;
            let imageBlob = await fetch(imageUrl).then(async (response) => await response.blob());
            let imageFile = new File([imageBlob], sl?.doc_master?.doc_master_lang?.name );
            await folder.file(sl?.doc_master?.doc_master_lang?.name+i+`.${extenion}` , imageFile);
            i++;
        }
    }
    let fileName = showBpkp == true ? `file_GCD${leadDetails?.dealer_id}_${leadDetails?.lead_id}_${leadDetails?.bpkp_number}` : `file_GCD${leadDetails?.dealer_id}_${leadDetails?.lead_id}`;
    folder.generateAsync({ type: "blob" }).then(content => saveAs(content, fileName));
  },

  async loadImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  },

  async handleDownloadPDF(docs,leadDetails,showBpkp=false) {
    const pdf = new jsPDF();

    for (let i = 0; i < docs?.length; i++) {
      const imageUrl = docs[i]?.doc_path_watermark;
      if(!imageUrl) continue;
      const imageSrc = await this.loadImage(imageUrl);

      if (i !== 0) {
        pdf.addPage();
      }
      pdf.addImage(imageSrc, "PNG", 10, 10, 180, 180);
    }
    let dealer_name = leadDetails?.dealer_name.split(' ').join('_');
    let fileName = showBpkp == true ? `file_${dealer_name}_${leadDetails?.lead_id}_${leadDetails?.bpkp_number}.pdf` : `file_${dealer_name}_${leadDetails?.lead_id}.pdf`;
    pdf.save(fileName);
}

};

export default finaljson;
